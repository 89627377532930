<template>
<form @submit.prevent="">
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان ویجت" id="title" v-model="data.title"></v-text-field>
        <div v-if="!isMobile" class="col-12">
            <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
        </div>
    </div>
    <div v-if="isMobile" class="form-row form-row-mobile-flex-modal" style="margin-top: 5px !important;margin-bottom: 2px;">
        <div class="col-12 col-md-4">
            <a href="" id="example-number-input5" @click.prevent="openDiagram()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-danger" style="max-width: 150px;margin-left: 10px;"><i class="uil uil-apps"></i> انتخاب شاخص</a>
            <div style="line-height: 1;padding-top: 15px;">
                <label for="example-number-input5">شاخص فعلی : {{ data.symbol }}</label>
            </div>
        </div>
    </div>
    <div v-if="!isMobile" class="form-row">
        <div class="col-4">
            <a href="" id="example-number-input5" @click.prevent="openDiagram()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
        </div>
        <div class="col-8" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-top: 12px;padding-right: 0 !important;">
            <label for="example-number-input5">شاخص فعلی : {{ data.symbol }}</label>
        </div>
    </div>

    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<style lang="scss">
    #main-header {
        z-index: 100000000;
    }

    #bug-report-modal,
    .modal[role="dialog"] {
        z-index: 999999999;
    }

    #indicator-modal {
        z-index: 2147483647;
    }
</style>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (تکنیکال) است
export default {
    name: 'SettingsTradingView',
    components: {},
    props: ['data'],
    data: function () {
        return {
            indicator: undefined,
            isMobile:false,
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            if (this.indicator) {
                this.data.symbol = this.indicator.symbol;
            }

            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings(data);
        },
        // این متد برای باز کردن پنجره انتخاب شاخص استفاده می شود
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                this.indicator = data;
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
    },
}
</script>
